import { AppProps } from "next/app";
import Head from "next/head";
import { Global, MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import { useEffect } from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useViewportSize } from "@mantine/hooks";
import { trpc } from "utils/trpc";
import NiceModal from "@ebay/nice-modal-react";
import "../styles/globals.css";
import "react-day-picker/dist/style.css";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import Router, { useRouter } from "next/router";
import dynamic from "next/dynamic";
import { ChannelProvider } from "ably/react";
import { theme } from "utils/theme";

const AblyProvider = dynamic(() => import("modules/Ably"), { ssr: false });

const AblyChannelProvider = ({ children }: { children: React.ReactNode }) => {
  const router = useRouter();
  const workspaceId = router.query.workspaceid as string;
  if (!workspaceId) return children;
  return <ChannelProvider channelName={workspaceId}>{children}</ChannelProvider>;
};

const setVh = (vh: number) => {
  document.documentElement.style.setProperty("--vh", vh + "px");
};

function App(props: AppProps) {
  const { height } = useViewportSize();

  const { Component, pageProps } = props;

  useEffect(() => {
    setVh(height);
  }, [height]);

  useEffect(() => {
    Router.events.on("routeChangeStart", NProgress.start);
    Router.events.on("routeChangeComplete", NProgress.done);
    Router.events.on("routeChangeError", NProgress.done);
    return () => {
      Router.events.off("routeChangeStart", NProgress.start);
      Router.events.off("routeChangeComplete", NProgress.done);
      Router.events.off("routeChangeError", NProgress.done);
    };
  }, []);

  return (
    <>
      <Head>
        <title>Querio</title>
        <meta name="description" content="Query your data using natural language" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <link rel="apple-touch-icon" sizes="180x180" href="/images/apple-touch-icon.png" />
        <link rel="apple-touch-icon" sizes="180x180" href="/images/android-chrome-192x192.png" />
        <link rel="apple-touch-icon" sizes="180x180" href="/images/android-chrome-512x512.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/images/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/images/favicon-16x16.png" />
        <link rel="icon" type="image/x-icon" href="/images/favicon.ico" />
        <link rel="manifest" href="/images/site.webmanifest"></link>
      </Head>
      <AblyProvider>
        <AblyChannelProvider>
          <GoogleOAuthProvider clientId={process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID as string}>
            <MantineProvider withGlobalStyles withNormalizeCSS theme={theme}>
              <NiceModal.Provider>
                <Notifications />
                <Global
                  styles={(theme) => ({
                    "*, *::before, *::after": {
                      boxSizing: "border-box",
                    },

                    body: {
                      margin: 0,
                      padding: 0,
                      ...theme.fn.fontStyles(),
                      lineHeight: theme.lineHeight,
                      maxHeight: "var(--vh, 100vh)",
                      minHeight: "var(--vh, auto)",
                    },

                    html: {
                      maxHeight: "-webkit-fill-available",
                    },
                  })}
                />
                {/* @ts-ignore */}
                <Component {...pageProps} />
              </NiceModal.Provider>
            </MantineProvider>
          </GoogleOAuthProvider>
        </AblyChannelProvider>
      </AblyProvider>
    </>
  );
}

export default trpc.withTRPC(App);
