import { TRPCClientError, httpBatchLink, httpLink } from "@trpc/client";
import { createTRPCNext } from "@trpc/next";
import type { AppRouter } from "trpc/_app";
import superjson from "superjson";
import { showNotification } from "@mantine/notifications";
import { Box } from "@mantine/core";

function getBaseUrl() {
  if (typeof window !== "undefined")
    // browser should use relative path
    return "";

  if (process.env.VERCEL_URL)
    // reference for vercel.com
    return `https://${process.env.VERCEL_URL}`;

  // assume localhost
  return `http://localhost:${process.env.PORT ?? 3001}`;
}

export const trpc = createTRPCNext<AppRouter>({
  config(opts) {
    return {
      links: [
        httpLink({
          /**
           * If you want to use SSR, you need to use the server's full URL
           * @link https://trpc.io/docs/ssr
           **/
          url: `${getBaseUrl()}/api/trpc`,

          // You can pass any HTTP headers you wish here
          async headers() {
            if (!opts.ctx?.req?.headers) return {};
            const { connection: _connection, ...headers } = opts.ctx.req.headers;
            return headers;
          },
        }),
      ],
      transformer: superjson,
      queryClientConfig: {
        defaultOptions: {
          mutations: {
            onError: (error) => {
              const getErrorMessage = (err: any): string => {
                const error: Error = err;
                try {
                  if (error instanceof TRPCClientError) {
                    // Zod Errors are a JSON array of errors
                    const parsedMessage = JSON.parse(error.message);
                    return parsedMessage[0].message ?? error.message;
                  }
                  return error.message;
                } catch (e) {
                  return error.message;
                }
              };
              const message = getErrorMessage(error);
              showNotification({
                id: message,
                color: "red",
                title: "Oops, something went wrong.",
                message: (
                  <Box
                    sx={(theme) => ({
                      "> a": {
                        color: theme.colors.blue[4],
                        textDecoration: "none",
                      },
                    })}
                    dangerouslySetInnerHTML={{
                      __html: message,
                    }}
                  />
                ),
              });
            },
          },
        },
      },
    };
  },
  /**
   * @link https://trpc.io/docs/ssr
   **/
  ssr: false,
});
